.PillGrid_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PillGrid_Headline {
    color: white;
}

.PillGrid {
    display: flex;
    align-self: center;
    max-width: 600px;
    margin: 0 auto;
    flex-flow: row wrap;
    justify-content: center;
    border: 1px solid black;
    flex-direction: column;
    overflow: hidden;
    height: 250px;
}

.CraftClaim_TextArea {
    width: 50%;
}

.PillGrid_Child {
    overflow-y: scroll;
}

.PillGrid_Loading {
    color: white;
    align-self: center;
    justify-self: center;
    text-align: center;
}

.CraftClaim_Title {
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
}
 

.CraftClaim_MoreInfo {
    margin-top: 0;
    color: white;
    max-width: 50%;
    text-align: center;
}

.CraftClaim_Description {
    text-align: center;
    background-color: #9d00ff;
    font-weight: 400;
    color: white;
    align-self: center;
}

.CraftClaim_Info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CraftClaim_Info p {
    margin: 0;
    margin-bottom: 10px;
}

.CraftClaim_MoreInfo {
    margin-top: 0;
}

.CraftClaim_Info span {
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.CraftClaim_Check {
    text-align: center;
    background-color: #9d00ff;
    font-weight: 400;
    color: white;
    align-self: center;
    cursor: pointer;
}

.CraftClaim_UsedCheckButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 5px 10px;
    -webkit-transition: opacity 200ms linear, color 200ms linear;
    transition: opacity 200ms linear, color 200ms linear;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
}

.CraftClaim_UsedCheckButton:hover {
    opacity: 0.5;
}

.CraftClaim_MaxNotice {
    font-size: 10px;
    color: black !important;
    text-transform: lowercase;
}


@media only screen and (max-width: 600px) {
    .CraftClaim_TextArea {
        width: 100%;
    }

    .CraftClaim_MoreInfo { 
        max-width: 75%;
    }
}