.CraftPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    margin-top: 20px;
}

.CraftPage_Full {
    height: 100vh;
}

.CraftPage_Headline {
    background-color: #9d00ff;
    font-weight: 400;
    font-size: 20px;
    color: black;
    align-self: center;
}

.CraftPage_Headline span {
    color: white;
}

.CraftPage_AddressFooter {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    margin: 0;
    min-height: 35px;
    cursor: pointer;
    width: 100%;
    background-color: white;
    color: black;
    text-align: center;
}

.CraftPage_MintButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
}

.CraftPage_MintButtonActive:hover {
    background-color: #9d00ff;
    color: #f2f3f5;
}

.CraftPage_MintButtonDisabled {
    cursor:auto !important;
    animation: glow 2.5s ease-in-out infinite;
}

.Main_ConnectButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


@media screen and (max-width: 600px) {
    .CraftPage_AddressFooter {
        font-size: 10px;
    }
}

@keyframes glow {
    0% {
      background-color: white;
    }
    50% {
      background-color: #9d00ff;
    }
    100% {
        background-color: white;
    }
  }

